function resetTransitionHomePage() {
  const bodyMain = document.getElementById("body")
  const transitionDiv = document.getElementById("transition")

  if (bodyMain.classList.contains("main")) {
    if (transitionDiv.classList.contains("fadeOut")) {
      transitionDiv.classList.add("fadeOutReset")
      //transitionDiv.classList.add("fadeOutPlay");
      setTimeout(function() {
        transitionDiv.classList.remove("fadeOut")
        //console.log("fadeOut class removed")
      }, 1010)
      setTimeout(function() {
        transitionDiv.classList.remove("fadeOutReset")
      }, 1015)
    }
  }
}

module.exports = resetTransitionHomePage
