import React from "react"
import { graphql, StaticQuery } from "gatsby"
//import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import resetTransitionHomePage from "../modules/resetTransitionOnHomePage"

const ServicesPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  if (typeof window !== "undefined") {
    setTimeout(resetTransitionHomePage, 1100)
  }

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Velvetzoo Services"
        keywords={[`Velvetzoo`, `Design services`, `Development`, `UX Design`]}
      />
      <Helmet>
        <body className="main services" id="body" />
      </Helmet>

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h1 className="big-font">Services</h1>

          <p style={{ maxWidth: 720, margin: "0 auto" }}>
            Working for companies or passionate entrepreneurs, the main idea is
            to help them build their vision. From branding to digital design
            this are some of the services offered with honest passion.
          </p>

          <div className="row">
            <div className="col-4">
              <h2>Web, digital, UX/UI Design</h2>
              <ul className="services">
                <li>UI/UX Design</li>
                <li>Digital product design</li>
                <li>Web and Webapp design</li>
                <li>Design system creation</li>
                <li>Digital communication</li>
                <li>Interactive design</li>
                <li>Prototyping</li>
                <li>Frontend development</li>
              </ul>
            </div>

            <div className="col-4">
              <h2>Branding & Identity</h2>
              <ul className="services">
                <li>Naming</li>
                <li>Brand identity</li>
                <li>Corporate identity</li>
                <li>Graphic and visual code</li>
                <li>Design systems</li>
                <li>Art direction</li>
                <li>Iconography</li>
              </ul>
            </div>

            <div className="col-4">
              <h2>Graphic, Editorial, Packaging</h2>
              <ul className="services">
                <li>Illustration</li>
                <li>Editorial design</li>
                <li>Poster design</li>
                <li>Brochure design</li>
                <li>Packaging</li>
                <li>Motion and animation</li>
                <li>Video</li>
              </ul>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    aboutImage: file(relativePath: { eq: "greg-rosemblun.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ServicesPage location={props.location} data={data} {...props} />
    )}
  />
)
